<template>
  <div class="survey">
    <div class="row">
      <member-list class="pt-3" :facets="boardFacets" :query="boardQuery" :fields="boardFields"
                   :title="this.$t('labels.board')"></member-list>
    </div>
  </div>
</template>
<script>
import MemberList from '@/components/lists/member/MemberList';

export default {
  name: 'history',
  props: {
    id: Number,
    localOffice: Object,
  },
  components: {
    MemberList,
  },
  computed: {
    boardQuery() {
      return {
        query: {
          bool: {
            must: [
              {
                match: {
                  'office.raw': this.localOffice.office.label,
                },
              },
              {
                bool: {
                  should: [
                    {
                      match: {
                        local_revision: '1',
                      },
                    },
                    {
                      range: {
                        local_member_board_assignments_id: {
                          gt: '0',
                        },
                      },
                    },
                  ],
                  minimum_should_match: '1',
                },
              },
            ],
          },
        },
      };
    },
  },
  data() {
    return {
      boardFacets: [
        {
          level: 1,
          id: 'officeFilter',
          field: 'office.raw',
          label: this.$t('labels.victim-support-centres'),
          placeholder: this.$t('labels.victim-support-centre'),
          type: 'list',
        },
        {
          level: 1,
          id: 'localofficeFilter',
          field: 'localoffice.raw',
          label: this.$t('labels.local-office'),
          placeholder: this.$t('labels.local-office'),
          type: 'list',
        },
        {
          level: 1,
          id: 'categoryFilter',
          field: 'category.raw',
          label: this.$t('labels.category'),
          placeholder: this.$t('labels.all-categories'),
          type: 'list',
          small: true,
        },
        {
          level: 1,
          id: 'nameFilter',
          field: 'full_name',
          label: this.$t('labels.name'),
          placeholder: `${this.$t('labels.name')}..`,
          type: 'search',
        },
        {
          level: 1,
          id: 'idFilter',
          field: 'id',
          label: this.$t('labels.member-number'),
          placeholder: `${this.$t('labels.member')} #..`,
          type: 'search',
        },
        {
          level: 2,
          id: 'genderSubFilter',
          field: 'gender.raw',
          label: this.$t('labels.gender'),
          placeholder: this.$t('labels.gender'),
          type: 'list',
          small: true,
        },
        {
          level: 2,
          id: 'localBoardAssignmentSubFilter',
          field: 'local_board_assignment.raw',
          label: this.$t('labels.board-local'),
          placeholder: this.$t('labels.board-local'),
          type: 'list',
        },
        {
          level: 2,
          id: 'regionalBoardAssignmentSubFilter',
          field: 'regional_board_assignment.raw',
          label: this.$t('labels.board-region'),
          placeholder: this.$t('labels.board-region'),
          type: 'list',
        },
        {
          level: 2,
          id: 'nationalBoardAssignmentSubFilter',
          field: 'national_board_assignment.raw',
          label: this.$t('labels.board-union'),
          placeholder: this.$t('labels.board-union'),
          type: 'list',
        },
        {
          level: 2,
          id: 'localNominationSubFilter',
          field: 'local_nomination.raw',
          label: this.$t('labels.nomination-committee-local'),
          placeholder: this.$t('labels.nomination-committee-local'),
          type: 'list',
          small: true,
        },
      ],

      boardFields: [
        {
          key: 'id',
          sort_field: 'id',
          label: '#',
          width: 6,
        },
        {
          key: 'first_name',
          sort_field: 'first_name.raw',
          label: this.$t('labels.first-name'),
          width: 11,
        },
        {
          key: 'last_name',
          sort_field: 'last_name.raw',
          label: this.$t('labels.last-name'),
          width: 11,
        },
        {
          key: 'office',
          sort_field: 'office.raw',
          label: this.$t('labels.victim-support-centre'),
          width: 12,
        },
        {
          key: 'local_board_assignment',
          sort_field: 'local_board_assignment.raw',
          label: this.$t('labels.board-local'),
          width: 14,
        },
        {
          key: 'workgroup',
          sort_field: 'workgroup.raw',
          label: this.$t('labels.working-group'),
          width: 14,
        },

        {
          key: 'mobile',
          sort_field: 'mobile.raw',
          label: this.$t('labels.mobile'),
          width: 12,
        },
        {
          key: 'email',
          sort_field: 'email.raw',
          label: '',
          width: 5,
        },
      ],
    };
  },
};
</script>
